<template>
  <v-autocomplete
    v-model="drug"
    v-bind="$attrs"
    :items="filteredDrugs"
    :loading="loading"
    :search-input.sync="search"
    :filter="function(item, queryText) {
      return (item.name.en && item.name.en.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1) ||
        (item.short_name && item.short_name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1) ||
        (item.brand_names && item.brand_names.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
    }"
    :rules="[v => !!v || 'Please select a drug']"
    item-text="name.en"
    item-value="id"
    :hide-no-data="!indication"
    return-object
    :cache-items="!allowedDrugs"
    error-count="2"
    :item-disabled="itemDisabled"
    :error-messages="errorMessages"
    :chips="$attrs.multiple"
    :deletable-chips="$attrs.multiple"
    @input="$emit('input', drug)"
  >
    <template #no-data>
      <v-list-item
        v-if="indication"
        dense
      >
        <v-list-item-content>
          <v-list-item-title>
            No drugs available with {{ pediatric ? 'pediatric' : null }} regimens for the selected indication
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template #item="{ item }">
      <v-list-item-content style="max-width:400px">
        <v-list-item-title>
          {{ item.name.en }}
          <v-icon
            v-if="item.no_oral_option"
            color="teal"
            class="ml-1 mb-1"
            small
          >
            fa-light fa-syringe
          </v-icon>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item.brand_names | truncate(50) }}
        </v-list-item-subtitle>
        <v-list-item-subtitle
          v-for="(error, index) in errors(item)"
          :key="index"
          class="red--text mt-1"
        >
          <v-icon
            x-small
            color="error"
            class="fa-fw mr-1"
            v-text="error.icon"
          />
          <span class="text-caption">{{ error.text }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <arkscore-gauge
          :key="'arkscore-gauge-' + item.score_adverse_reaction"
          :value="item.score_adverse_reaction"
          :size="30"
          right
        />
      </v-list-item-action>
    </template>
    <template
      v-if="!hideArkscore"
      #prepend
    >
      <arkscore-gauge
        :value="drug && drug.score_adverse_reaction ? drug.score_adverse_reaction : null"
        :size="40"
        class="mt-n3"
      />
    </template>
    <template #selection="{ item }">
      <template v-if="!noDisplay">
        {{ showShortName ? item.short_name : item.name.en }}
      </template>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  components: {
    ArkscoreGauge: () => import('@/components/arkscore/ArkscoreGauge.vue'),
  },
  props: {
    value: {
      type: [Object, Array],
      default: null,
    },
    method: {
      type: String,
      default: null,
    },
    results: {
      type: Object,
      default: () => ({}),
    },
    resistance: {
      type: Array,
      default: () => ([]),
    },
    sensitivities: {
      type: Array,
      default: () => ([]),
    },
    allergies: {
      type: Array,
      default: () => ([]),
    },
    allowedDrugs: {
      type: Array,
      default: () => ([]),
    },
    pregnant: {
      type: Boolean,
      default: false,
    },
    pediatric: {
      type: Boolean,
      default: false,
    },
    formulary: {
      type: Object,
      default: () => ({}),
    },
    source: {
      type: String,
      default: null,
    },
    indication: {
      type: [Number, String],
      default: null,
    },
    hideArkscore: {
      type: Boolean,
      default: false,
    },
    noDisplay: {
      type: Boolean,
      default: false,
    },
    showShortName: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loading: false,
      search: null,
      drug: this.value,
      drugs: Array.isArray(this.value) && !this.value.id ? this.value : [],
    }
  },
  computed: {
    errorMessages () {
      return this.drug?.id ? this.errors(this.drug).map(error => error.text) : []
    },
    filteredDrugs () {
      let drugs = this.drugs
      if (this.allowedDrugs.length) {
        drugs = drugs.filter(x => this.allowedDrugs.includes(x.id))
      }
      if (this.indication) {
        drugs = drugs.filter(x => x.regimens).filter(x => x.regimens.filter(x => x.pediatric == this.pediatric).flatMap(x => x.indications).flatMap(x => x.id).includes(this.indication))
      }
      // if (this.sensitivities.length) {
      //   drugs = drugs.filter(x => this.sensitivities.filter(s => s.result == 'S').some(s => s.name == x.name))
      // }
      return drugs
    },
  },
  watch: {
    search (term) {
      // if (!this.drug || (this.drug && !this.drug.name.includes(term)) ) {
        this.fetchDrugs(term)
      // }
    },
    value () {
      if (this.value?.id && !this.drugs.includes(this.value)) {
        this.drugs.push(this.value)
      }
      this.drug = this.value
    },
    drugs: {
      deep: true,
      handler () {
        if (this.value?.id && !this.drugs.includes(this.value)) {
          this.drugs.push(this.value)
        }
      },
    },
  },
  created () {
    if (this.value?.id) {
      this.drugs.push(this.value)
    } else {
      this.fetchDrugs()
    }
  },
  methods: {
    fetchDrugs (search) {
      this.loading = true
      this.axios.get('admin/drugs', {
        params: {
          search: search,
          id: this.allowedDrugs,
          count: 40,
          formulary: this.formulary.id,
        },
      })
        .then((res) => {
          this.drugs = res.data.data
        })
        .catch(error => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        })
        .finally(() => {
          this.loading = false
          if (this.value?.id && !this.drugs.includes(this.value)) {
            this.drugs.push(this.value)
          }
        })
    },
    errors (drug) {
      var messages = []
      if (drug && (drug.category >= 'C' || drug.category == '?') && this.pregnant) {
        messages.push({text: `Category ${drug.category >= 'C' ? drug.category : 'unassigned'}`, icon: 'fa fa-person-pregnant'})
      }
      if (drug && drug.no_pediatric_use && this.pediatric) {
        messages.push({text: 'Not for use with pediatric patients', icon: 'fa fa-child'})
      }
      if (drug && this.allergies && this.allergies.flatMap(x => x.group.drug_classes).some(x => x.id == drug.class_id)) {
        messages.push({text: `${this.allergies.flatMap(x => x.group.drug_classes).filter(x => x.id == drug.class_id).map(x => x.name)} allergy reported`, icon: 'fa fa-allergies'})
      }
      if (drug?.resistance && drug.resistance.map(dr => dr.id).some(r => this.resistance.map(tr => tr.id).includes(r))) {
        var resistance = drug.resistance.map(o => o.short_name ? o.short_name : o.name.en).filter(r => this.resistance.map(o => o.short_name ? o.short_name : o.name.en).includes(r))[0]
        messages.push({text: `${ this.$options.filters.capitalize(resistance, { onlyFirstLetter: true }) } resistance detected`, icon: 'fa fa-shield-virus'})
      }
      if (drug && drug.name && this.sensitivities.length) {
        if (this.sensitivities.filter(s => s.result == 'R').some(s => s.name.en == drug.name.en)) {
          messages.push({text: `${drug.short_name} resistance detected`, icon: 'fa fa-square-r'})
        } else if (this.sensitivities.filter(s => s.result != 'S').some(s => s.name.en == drug.name.en)) {
          messages.push({text: `${drug.short_name} sensitivity not detected`, icon: 'fa-regular fa-square-i'})
        } else if (!this.sensitivities.filter(s => s.result == 'S').some(s => s.name.en == drug.name.en)) {
          messages.push({text: `${drug.short_name} not tested for sensitivity`, icon: 'fa-regular fa-square-question'})
        }
      }
      if (drug && drug.ineffective_sources && drug.ineffective_sources.includes(this.source)) {
        messages.push({text: `Not effective in ${this.source}`, icon: this.$sources.find(x => x.value === this.source.toLowerCase()).icon.replace('fal', 'fa')})
      }
      return messages
    },
    itemDisabled (drug) {
      let disabled = false
      if (this.allowedDrugs.length) {
        disabled = !this.allowedDrugs.includes(drug.id)
      }
      if (this.method == 'culture' && this.sensitivities.length) {
        disabled = disabled || !this.sensitivities.some(s => s.name.en == drug.name.en)
        disabled = disabled || !this.sensitivities.filter(s => s.result == 'S').some(s => s.name.en == drug.name.en)
      }
      return disabled
    },
  },
}
</script>
